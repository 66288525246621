import { computed } from 'vue'

export type NotificationType = 'success' | 'info' | 'error' | 'warning' | 'message'

export enum NotificationTypeEnum {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  MESSAGE = 'message',
}

export type NotificationId = string | number

export interface Notification {
  id?: NotificationId
  type: NotificationType
  message: string
  closable?: boolean
  timeout?: number | false
  backgroundColor?: string | null
}

export enum NotificationBackgroundType {
  green,
  orange,
  yellow,
  black,
}

export interface Notifications extends Omit<Notification, 'message'> {
  messages: Notification[]
}

export function useNotificationStyles(type: NotificationType) {
  const notificationIcon = computed(() => {
    switch (type) {
      case NotificationTypeEnum.MESSAGE: {
        return {
          toast: null,
          systemNotification: null,
        }
      }
      case NotificationTypeEnum.SUCCESS: {
        return {
          toast: {
            name: 'check',
            width: 20,
            height: 15,
          },
          systemNotification: {
            name: 'check',
            width: 16,
            height: 12.05,
          },
        }
      }

      case NotificationTypeEnum.ERROR:
      case NotificationTypeEnum.WARNING: {
        return {
          toast: {
            name: 'warning-outline',
            width: 28,
            height: 28,
          },
          systemNotification: {
            name: 'warning-outline',
            width: 20,
            height: 20,
          },
        }
      }

      default: {
        return {
          toast: {
            name: 'info-outline',
            width: 28,
            height: 28,
          },
          systemNotification: {
            name: 'info-outline',
            width: 24,
            height: 24,
          },
        }
      }
    }
  })

  const notificationClasses = computed(() => {
    switch (type) {
      case NotificationTypeEnum.MESSAGE:
        return {
          background: `bg-notification-custom`,
          message: 'text-notification-custom',
        }
      case NotificationTypeEnum.SUCCESS:
        return {
          background: `bg-notification-success`,
          message: 'text-notification-success',
        }
      case NotificationTypeEnum.ERROR:
        return {
          background: `bg-notification-error`,
          message: 'text-notification-error',
        }
      case NotificationTypeEnum.WARNING:
        return {
          background: `bg-secondary`,
          message: 'text-secondary',
        }
      default:
        return {
          background: `bg-notification-info`,
          message: 'text-notification-info',
        }
    }
  })
  return {
    notificationIcon,
    notificationClasses,
  }
}
